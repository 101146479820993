/* You can add global styles to this file, and also import other style files */

html,body {
  font-family: roboto !important;
  background-color: #FFFFFF;
  height:100%
}
.bold {
  font-weight: bold !important;
}
.positive {
  color:green;
  font-weight: bold
}
.negative {
  color:red;
  font-weight: bold
}
.positive .img-down{
  display:none;
}
.positive .img-up{
  display:inline-block;
}
.negative .img-up{
  display:none;
}
.negative .img-down{
  display:inline-block;
}

.padding-bottom {
  padding:0 !important;
  margin:0 !important;
}

.top-font-1 {
  font-size: 55px !important;
  text-transform: uppercase;
}

.top-font-2 {
  font-size: 35px !important;
  text-transform: uppercase;
}

.top-font-3 {
  font-size: 20px !important;
  text-transform: uppercase;
}

.top-font-4 {
  font-size: 15px !important;
  text-transform: uppercase;
}
.top-font-reputation-4{
  font-size: 15px !important;
}

.top-font-5 {
  font-size: 13px !important;
  text-transform: uppercase;
}
.top-font-6{
  font-size: 10px !important;
}

.img-last-month {
  max-height: 15px !important;
  max-width: 15px !important;
}

.img-info {
  max-height: 30px !important;
  max-width: 30px !important;
  margin-right: 10px !important;
}

.titulo {
  background-color: #0F2A3E;
  color: white;
  margin-top: 10px !important;
  text-transform: uppercase;
}

.star{
  display:flex;
  flex-direction: inline;
  justify-content: center; 
}
.starPanel{
  display:flex;
  flex-direction: inline;
  justify-content: center; 
}

.hidden{
  display: none !important;;
}
.visible{
  display:inline-block
}

.low{
  background-color: #f2dede!important;
}

.neutral{
  background-color: white !important
}
.high{
  background-color: #DFF0DA!important;
}
.high .fa-arrow-down{
  display:none;
}
.high .fa-arrow-up{
  display:inline-block;
}
.low .fa-arrow-up{
  display:none;
}
.low .fa-arrow-down{
  display:inline-block;
}
.neutral .fa-arrow-up{
  display:none;
}
.neutral .fa-arrow-down{
  display:none;
}
.fa-arrow-down{
  color:red;
  
}
.fa-arrow-up{
  color:green;
  
}
.celda{
  margin: 0px!important;
  font-size: 15px!important;
  font-weight: bold !important;
}